























import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  CountListingsRequest,
  PropertyCategory,
  ImageSourceSet,
} from 'client-website-ts-library/types';

import { API } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import Notice from '@/components/Notice.vue';
import Embeddable from '@/components/Common/Embeddable.vue';
import Listings from '../components/Listings.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';
import WhatsYourHomeWorth from '../components/WhatsYourHomeWorth.vue';
import VideoBanner from '../components/VideoBanner.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';
import VideoTile from '../components/VideoTile.vue';

@Component({
  components: {
    Listings,
    ItemCycler,
    Embeddable,
    WhatsYourHomeWorth,
    VideoBanner,
    VideoTile,
  },
})
export default class Home extends Mixins(View) {
  public imageSpecials: ImageSourceSet = { source: '/assets/images/specials/', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public imageProperties: ImageSourceSet = { source: '/assets/images/properties/', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public imageGuestInformation: ImageSourceSet = { source: '/assets/images/guest-information/', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public imageManagementServices: ImageSourceSet = { source: '/assets/images/management-services/', formats: ['webp', 'jpg'], fallback: 'jpg' };

  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Rural,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    PageSize: 6,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/4', formats: ['webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/5', formats: ['webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/6', formats: ['webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/7', formats: ['webp', 'jpg'], fallback: 'jpg' }),
  ];

  mounted() {
    // API.Listings.Bulk([
    //   new CountListingsRequest(new ListingFilter({ Statuses: [ListingStatus.Sold] }), (res) => {
    //     this.SoldCount = res.Count;
    //   }),
    //   new CountListingsRequest(new ListingFilter({ Statuses: [ListingStatus.Leased] }), (res) => {
    //     this.leasedCount = res.Count;
    //   }),
    //   new CountListingsRequest(new ListingFilter({ Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural,
    //     ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business],
    //   Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
    //     this.buyCount = res.Count;
    //   }),
    //   new CountListingsRequest(new ListingFilter({ Categories: [ListingCategory.ResidentialRental], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
    //     this.rentCount = res.Count;
    //   }),
    // ]);
  }
}
