
















import { Component, Prop, Vue } from 'vue-property-decorator';
import VideoPlayerSource from '@/app_code/VideoPlayerSource';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';
import WhatsYourHomeWorth from './WhatsYourHomeWorth.vue';

@Component({
  components: {
    WhatsYourHomeWorth,
  },
})
export default class VideoBanner extends Vue {
  @Prop({ default: false })
  private loop!: boolean;

  @Prop({ default: false })
  private autoplay!: boolean;

  @Prop()
  private readonly ratio!: number;

  @Prop()
  private readonly poster!: string;

  @Prop()
  private readonly video!: VideoPlayerSource;

  @Prop({ default: '' })
  private animateText!: string;

  @Prop({ default: '' })
  private label!: string;

  private office: Office | null = null;

  mounted() {
    this.loadOffice();
  }

  loadOffice(): void {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
