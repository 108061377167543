
























import { Component, Vue, Prop } from 'vue-property-decorator';

import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class NavigationHolidayLet extends Vue {
    @Prop()
    private mobileNavActive!: boolean;

    @Prop()
    private readonly isStuck!: boolean;

    scrollMeTo(name: string) {
      const element = document.getElementById(name);
      const top = element!.offsetTop;
      const headerOffset = 100;
      const elementPosition = element!.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      this.$emit('mobileNavActive', this.mobileNavActive = false);
    }
}
