import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/frequently-asked-questions',
    name: 'Frequently Asked Questions',
    component: () => import('../views/FrequentlyAskedQuestions.vue'),
  },
  {
    path: '/arrival-&-departure-information',
    name: 'Arrival & Departure Information',
    component: () => import('../views/ArrivalAndDepartureInformation.vue'),
  },
  {
    path: '/terms-&-conditions',
    name: 'Terms & Conditions',
    component: () => import('../views/TermsAndConditions.vue'),
  },
  {
    path: '/code-of-conduct',
    name: 'Code of Conduct',
    component: () => import('../views/CodeOfConduct.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
  },
  {
    path: '/why-list-with-us',
    name: 'Why List With Us',
    component: () => import('../views/WhyListWithUs.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    path: '/leave-a-review',
    name: 'Leave a Review',
    component: () => import('../views/TestimonialReview.vue'),
  },
  {
    path: '/holiday-let',
    name: 'Holiday Let Cairns',
    component: () => import('../views/HolidayLet.vue'),
  },
  {
    path: '/last-minute-specials',
    name: 'Last Minute Specials',
    component: () => import('../views/LastMinuteSpecials.vue'),
  },
  {
    path: '/multiple-night-discounts',
    name: 'Multiple Night Discounts',
    component: () => import('../views/MultipleNightDiscounts.vue'),
  },
  {
    path: '/newest-additions',
    name: 'Newest Additions',
    component: () => import('../views/NewestAddition.vue'),
  },
  {
    path: '/properties',
    name: 'Properties',
    component: () => import('../views/Properties.vue'),
  },
  {
    path: '/about-us',
    name: 'About Us',
    meta: { hide: true },
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog Details',
    meta: { hide: true },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: { hide: true },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: { hide: true },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/profiles/${slug.Slug}`,
                replace: true,
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    if (to.hash) return { selector: to.hash };

    return { x: 0, y: 0 };
  },
});

export default router;
